<template lang="pug">
CanvasModal.published-to-fb-modal(
  title="Publish to Facebook completed"
  :subtitle="`Title: ${getProjectDetails.name} (${getCanvasSize.width} x ${getCanvasSize.height} px)`"
  :isShow="true"
  isGreen=true
  @closeOverlay="closeVideoPreview"
)
  .published-to-fb-modal__container

    template(
      v-if="getExportDetailStates.publishedType === 'image'"
    )
      .all-scenes-image-preview
        div
          img(
            :src="getExportDetailStates.publishedUrl"
          )

    template(v-else)
      .video-preview(
        ref="projectPreviewWrapper"
      )
        button.btn-play-video-preview(
          :class="{ 'is-playing': isPlaying }"
          @click="togglePlay"
          type="button"
        )
          i.icon.icon-play
        .video-preview__container
          video.video-preview__video(
            :class="[{ 'is-vertical': getCanvasSize.width < getCanvasSize.height, 'is-landscape': getCanvasSize.width > getCanvasSize.height }]"
            ref="videoPreview"
            loop
            preload
          )
            source(:src="videoUrl")

    .published-to-fb-modal__action
      h2.published-to-fb-modal__title Your design has been published
      .published-to-fb-modal__buttons
        BaseButton(
          :href="mediaLibraryAssetLink"
          newTab=true
          isPrimary=true
          isTall=true
        ) Go to Media Library
        BaseButton(
          @click="downloadAsset"
          isPrimaryHollow=true
          isTall=true
        ) Download
        BaseButton(
          :href="createNewLink"
          isPrimaryHollow=true
          isTall=true
        ) Create New

</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { homeUrl } from '@/assets/scripts/variables';

export default {
  name: 'PublishedToFBPreview',
  data() {
    return {
      videoUrl: '',
      isPlaying: false,
    };
  },
  computed: {
    ...mapGetters(['getFBBusinessAccountForPublish']),
    ...mapGetters('canvasElements', [
      'getCanvasSize',
      'getProjectDetails',
    ]),
    ...mapGetters('exportDetails', [
      'getExportDetailStates',
    ]),
    mediaLibraryAssetLink() {
      return `https://business.facebook.com/asset_library/business_creatives/?business_id=${this.getFBBusinessAccountForPublish.id}&object_id=${this.getExportDetailStates.publishedId}`;
    },
    createNewLink() {
      return `${homeUrl()}/facebook-app`;
    },
  },
  methods: {
    ...mapMutations('exportDetails', [
      'updateExportDetailState',
    ]),
    closeVideoPreview() {
      this.updateExportDetailState({
        isExporting: false,
        isCompleted: false,
        percent: 0,
      });

      if (this.getExportDetailStates.publishedToFB) {
        // Reset publish to fb state
        this.updateExportDetailState({
          publishedToFB: false,
          publishedId: 0,
          publishedUrl: '',
          publishedType: 'image',
        });
      }
    },
    togglePlay() {
      this.isPlaying = !this.isPlaying;
    },
    updateData() {
      const { publishedUrl } = this.getExportDetailStates;
      const { duration } = this.getProjectDetails;

      let midDuration = parseInt(duration / 2, 10);
      midDuration = midDuration < 1 ? 1 : midDuration;

      this.videoUrl = `${publishedUrl}#t=${midDuration}`; // seek to middle part of the video
    },
    downloadAsset() {
      const { publishedUrl } = this.getExportDetailStates;

      if (!publishedUrl) return;

      const a = document.createElement('A');
      a.href = publishedUrl;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  mounted() {
    this.updateData();
  },
  watch: {
    url() {
      // to reload the video when canvas element is updated
      this.$el.getElementsByTagName('video')[0].load();
    },
    isPlaying() {
      if (this.isPlaying) {
        this.$refs.videoPreview.currentTime = 0;
        this.$refs.videoPreview.play();
      } else {
        this.$refs.videoPreview.pause();
      }
    },
  },
};
</script>

<style lang="scss">
.published-to-fb-modal__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .small {
    font-size: 0.75em;
    margin: 5px 0;
    color: $grey;
  }
  .all-scenes-image-preview{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    > div{
      flex-direction: column;
      display: flex;
      margin: 10px;

      img{
        margin: 5px;
        height: 520px;
        width: auto;
      }
    }
  }
}

.published-to-fb-modal__action,
.video-preview {
  height: 520px;
}

.video-preview {
  position: relative;
  overflow: hidden;
  width: 520px;
}

.video-preview__container {
  width: 100%;
  height: 100%;
}

.video-preview__video {
  width: 100%;
  height: 100%;
  object-fit: cover;

  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  &.is-vertical {
    width: auto;
    height: 100%;
  }

  &.is-landscape {
    width: 100%;
    height: auto;
  }
}

.btn-play-video-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue;
  font-size: 78px;
  cursor: pointer;
  z-index: 2;

  &:hover {
    opacity: 0.9;
  }

  &.is-playing {
    opacity: 0;
  }

  &:not(.is-playing) {
    & ~ .video-preview__container {
      .video-preview__video {
        opacity: 0.4;
      }
    }
  }
}

.published-to-fb-modal__action {
  padding-left: 20px;

  .published-to-fb-modal__title {
    font-size: 3em;
  }
}

.published-to-fb-modal__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;

  > * {
    width: 75%;
    margin-bottom: 15px;
  }
}

.published-to-fb-modal__container{
  .project-preview{
    margin-left: 30px;
    margin-top: 30px;

    .scenes__canvas {
      transform-origin: top left;
    }
  }
}
</style>
